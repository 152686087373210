import { Trans, t } from "@lingui/macro";
import { Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import styled from "@emotion/styled";
import React, { useState } from "react";
import HeroLanding from "../../../components/HeroLanding";
import SecondaryButton from "../../../components/SecondaryButton";
import Seo from "../../../components/seo";
import Header from "../../../components/Header";
import { theme } from "../../../themeV2";
import Container from "../../../components/Container";
import ProductModal from "../../../components/ProductModal";
import DefaultSection from "../../../components/landings/DefaultSection";
import ArrowButton from "../../../components/ArrowButton";
import CardColor from "../../../components/CardColor";
import sec1Icon1 from "../../../images/solutions/blockchain/sec-1_icon-1.png";
import sec1Icon2 from "../../../images/solutions/blockchain/sec-1_icon-2.png";
import sec1Icon3 from "../../../images/solutions/blockchain/sec-1_icon-3.png";
import sec1Icon4 from "../../../images/solutions/blockchain/sec-1_icon-4.png";
import sec2Bg from "../../../images/solutions/blockchain/sec-2_bg.png";
import sec3Tab2 from "../../../images/solutions/blockchain/sec-3_tab-2.png";
import sec3Tab3 from "../../../images/solutions/blockchain/sec-3_tab-3.png";
import greenIcon from "../../../images/solutions/blockchain/icon-green.svg";
import blueIcon from "../../../images/solutions/blockchain/icon-blue.svg";
import yellowIcon from "../../../images/solutions/blockchain/icon-yellow.svg";
import LandingsTabs from "../../../components/landings/LandingsTabs";

const renderCard = ({ color, icon, title, description }) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
    >
      <CardColor
        mt={0}
        color={color}
        icon={icon}
        title={title}
        description={description}
      />
    </Grid>
  );
};

const metadata = {
  title: "Blockchain",
  description:
    "A dedicated software company focused on developing solutions for the entire fund industry. By leveraging the power of blockchain technology, we can provide you with a secure and reliable platform offering improved performance, scalability, and cost savings, while also providing enhanced security for your operations.",
};

const CARDS_ITEMS = [
  {
    icon: yellowIcon,
    color: theme.palette.colors.yellow.main,
    title: <Trans>Optimize financial planning process for advisors and clients</Trans>,
    description: (
      <Trans>
        Digital tools streamline financial planning, enhancing efficiency, personalization, and trust for advisors and clients.
      </Trans>
    ),
  },
  {
    icon: blueIcon,
    color: theme.palette.colors.blue.chart,
    title: <Trans>Powerful interaction and easy to compare different scenario</Trans>,
    description: (
      <Trans>
        Powerful tools enable easy scenario comparisons, enhancing interactive, informed decision-making for clients and advisors.
      </Trans>
    ),
  },
  {
    icon: greenIcon,
    color: theme.palette.colors.green.main,
    title: <Trans>Get awesome financial planning reports</Trans>,
    description: (
      <Trans>
        Generate impressive financial reports that simplify insights and empower client decision-making.
      </Trans>
    ),
  },
];

const SEC_1_ITEMS = [
  {
    icon: sec1Icon1,
    title: t`A business platform ready to deliver savings`,
    description: t`An opportunity to deliver savings in a blockchain ecosystem and to understand the best adaptation strategy in a constantly changing environment`,
  },
  {
    icon: sec1Icon2,
    title: t`A way to be part of a new value chain`,
    description: t`It’s crucial to understand how the technology and its adoption can drastically reduce current core dealing activities in the value chain in every sector`,
  },
  {
    icon: sec1Icon3,
    title: t`A technical solution tailored to a business ecosystem`,
    description: t`Dedicated privacy and anonymity features for data governance issues in blockchain ecosystems`,
  },
  {
    icon: sec1Icon4,
    title: t`Minimise disruptions`,
    description: t`Avoid long and expensive integration downtime when using our fully integrated tools`,
  },
];

const SEC_3_ITEMS = [
  {
    cards: true,
    direction: "row-reverse",
    tabTitle: t`FAST: Transfer Solution`,
    title: t`Orchestrate fully digitalized transfer dealing flows`,
    description: (
      <>
        <Typography style={{ marginTop: "6px" }}>
          <Trans>
            An integrated solution for the complete lifecycle of transfers with
            one single goal: a better service for end investors, providing
            greater efficiency to entities in a simplified technical integration
            scenario.
          </Trans>
        </Typography>
        <Grid container spacing={2} ml="-24px!important" textAlign="left" flexDirection={{ sm: "column", md: "row" }} alignItems={{ xs: "center", md: "unset"}}>
          {CARDS_ITEMS.map((item) => renderCard({ ...item }))}
        </Grid>
      </>
    ),
  },
  {
    img: sec3Tab2,
    tabTitle: t`Digital Assets - Tokenization`,
    title: t`Introducing asset tokenization to the fund sector`,
    description: (
      <>
        <Typography>
          <Trans>
            Allfunds Blockchain boosts traditional fund business efficiency and
            opens doors to the digital asset ecosystem future.
          </Trans>
        </Typography>
        <List sx={{ marginTop: "0!important" }}>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemIcon sx={{ minWidth: "unset", marginRight: 1 }}>
              <FontAwesomeIcon
                icon={faCheck}
                fontSize="19px"
                color={theme.palette.text.primary}
              />
            </ListItemIcon>
            <ListItemText>
              Allfunds Blockchain has collaborated and advised on sandbox
              initiatives and was one of the main participants in successfully
              launching Spain’s first tokenized fund in 2022.
            </ListItemText>
          </ListItem>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemIcon sx={{ minWidth: "unset", marginRight: 1 }}>
              <FontAwesomeIcon
                icon={faCheck}
                fontSize="19px"
                color={theme.palette.text.primary}
              />
            </ListItemIcon>
            <ListItemText>
              Support with regulation: Allfunds Blockchain is an active
              supporter of the regulatory process and can assist in all phases
              of regulation and policy setting.
            </ListItemText>
          </ListItem>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemIcon sx={{ minWidth: "unset", marginRight: 1 }}>
              <FontAwesomeIcon
                icon={faCheck}
                fontSize="19px"
                color={theme.palette.text.primary}
              />
            </ListItemIcon>
            <ListItemText>
              A new ecosystem of business and services: A fully integrated fund
              management workflow with a digitalized and programmable fund
              lifecycle for an end-to-end fund distribution traceability.
            </ListItemText>
          </ListItem>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemIcon sx={{ minWidth: "unset", marginRight: 1 }}>
              <FontAwesomeIcon
                icon={faCheck}
                fontSize="19px"
                color={theme.palette.text.primary}
              />
            </ListItemIcon>
            <ListItemText>
              New distribution channels: The new era of Digital Assets can
              unlock new distribution channels and increase business
              opportunities.
            </ListItemText>
          </ListItem>
        </List>
      </>
    ),
  },
  {
    img: sec3Tab3,
    direction: "row-reverse",
    tabTitle: t`Active partnerships`,
    title: t`Blockchain Revolutionizing Funds`,
    description: (
      <Typography>
        <Trans>
          Allfunds Blockchain partners with top industry players to implement
          blockchain solutions, leveraging their fund business expertise to
          address industry challenges and save costs.
          <br /><br />
          By leading global working groups, they help over 40 industry players
          explore blockchain benefits and shape the future industry.
        </Trans>
      </Typography>
    ),
  },
];

function Blockchain() {
  const [showModal, setShowModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(SEC_3_ITEMS[0].tabTitle);
  const selectedItem = SEC_3_ITEMS.find((item) => item.tabTitle === selectedTab);
  const renderRequestButton = () => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => setShowModal(true)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderArrowButton = (link) => {
    return (
      <CustomArrowButton
        isDark
        variant="text"
        color="secondary"
        title="Know more"
        contentColor={theme.palette.secondary.main}
        extraStyles={{ px: "0 !important" }}
        onClick={() => window.open(link, "_blank")}
      />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Seo title={metadata.title} description={metadata.description} />
      <Header />
      <HeroLanding
        title={<Trans>The way to lead change</Trans>}
        description={
          <Trans>
            A dedicated software company focused on developing solutions for the
            entire fund industry. By leveraging the power of blockchain
            technology, we can provide you with a secure and reliable platform
            offering improved performance, scalability, and cost savings, while
            also providing enhanced security for your operations.
          </Trans>
        }
        handleOnclickInfo={() => setShowModal(true)}
        multimedia="https://app.allfunds.com/docs/cms/hero_4da7ba8365.mp4"
        isVideo={true}
        widthImage={{ xs: "100%" }}
        kind="Blockchain"
        kindColor={theme.palette.colors.blueDark.main}
        buttonJustify={{ xs: "center", sm: "flex-start" }}
        grid={{
          columnLeft: { md: 6.5 },
          columnRight: { md: 5.5 },
        }}
      />
      <DefaultSection
        textAlign="center"
        pretitleAlign="center"
        containerPy={{ xs: 8, lg: 5 }}
        pretitle={t`Why Allfunds Blockchain?`}
        pretitleColor={theme.palette.colors.blueDark.main}
        title={t`A live blockchain environment`}
        description={
          <>
            <Typography width="70%" style={{ margin: "12px auto 0" }}>
              <Trans>
                Allfunds Business Smart Contracts solution: a blockchain
                technology for fund distribution operations that serves as a
                roadmap strategy for the optimal implementation and use of
                blockchain in the value chain.
              </Trans>
            </Typography>
            <Grid container spacing={2} style={{ marginLeft: "-24px" }}>
              {SEC_1_ITEMS.map((item) => (
                <Grid
                  item
                  xs={12}
                  md={3}
                  spacing={1}
                  textAlign="left"
                >
                  <img
                    src={item.icon}
                    alt="Blockchain - Allfunds"
                    width="48"
                    height="48"
                  />
                  <Typography fontWeight="700" mt={1}>{item.title}</Typography>
                  <Typography mt={1}>{item.description}</Typography>
                </Grid>
              ))}
            </Grid>
          </>
        }
      />
      <ParallaxStack
        component="span"
        sx={{
          background: `url(${sec2Bg}) center / cover no-repeat fixed;`,
        }}
      >
        <DefaultSection
          color={theme.palette.colors.white.main}
          py={5}
          textAlign="center"
          title={t`Allfunds Blockchain is at the forefront of innovation, as demonstrated by its role in the successful launch of Spain's first tokenized fund in 2022.`}
          description={
            <Stack alignItems="center">
              <Typography width="80%">
                <Trans>
                  With a dedicated staff of over 20 experts in three countries,
                  the team covers Allfunds' global markets and provides
                  blockchain-based solutions to all fund value chain stakeholders.
                </Trans>
              </Typography>
            </Stack>
          }
        />
      </ParallaxStack>
      <Container component="section" py={{ xs: 8, md: 10, lg: 8 }}>
        <LandingsTabs
          value={selectedTab}
          valueSet={setSelectedTab}
          tabs={SEC_3_ITEMS.map((item) => item.tabTitle)}
          tabColor={theme.palette.colors.blueDark.main}
          tabBgColor={theme.palette.colors.blueDark.light}
        />
        <DefaultSection
          mt={{ xs: 2 }}
          mb={{ xs: 3, md: 4 }}
          textAlign={selectedItem.cards ? "center" : "left"}
          alignItems={selectedItem.cards && "center"}
          padding="0!important"
          title={selectedItem.title}
          description={selectedItem.description}
          pretitleColor={theme.palette.colors.blueDark.main}
          multimedia={selectedItem.img}
          width={selectedItem.cards && { md: "90%", lg: "80%" }}
        />
        <Stack direction="row" justifyContent="center" spacing={2}>
          {renderRequestButton()}
          {renderArrowButton()}
        </Stack>
      </Container>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Blockchain"
          product="blockchain"
          hasTemplate={false}
        />
      )}
    </ThemeProvider>
  );
}

const ParallaxStack = styled(Stack)`
    p {color: ${theme.palette.colors.white.main};}
`;
const CustomArrowButton = styled(ArrowButton)`
    p {font-weight: 700!important}
`;

export default Blockchain;
